@import '../../scss/_variables';
@import '../../scss/_mixins';

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  background-color: $background-light;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;

  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .navbar-logo {
      display: flex;
      align-items: center;

      img {
        height: 40px;
        margin-right: 10px;
      }
    }

    .mobile-menu {
      position: fixed;
      top: 0;
      right: 0;
      background-color: $color-white;
      height: 100vh;
      width: 300px;
      box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3);
      padding: 20px;
      transform: translateX(100%);
      opacity: 0;
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
      z-index: 100000000000000;

      &.open {
        transform: translateX(0);
        opacity: 1;
      }

      .mobile-menu-header {
        font-family: $font-family-primary;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Raleway', sans-serif;
        font-size: 32px;
        font-weight: 300;
        line-height: 48px;
        text-align: left;
        color: $color-black;

        .close-menu {
          background: none;
          border: none;
          cursor: pointer;
          img {
            height: 24px;
            width: 24px;
          }
        }
      }

      .mobile-links {
        list-style: none;
        padding: 0;
        margin-top: 30px;

        li {
          margin-bottom: 20px;

          a {
            font-family: $font-family-primary;
            font-size: $font-size-small;
            font-weight: $font-weight-light;
            color: $color-black;
            text-decoration: none;
            transition: color 0.3s;

            &:hover {
              color: $button-primary-background;
            }

            &.active {
              font-weight: $font-weight-semi-bold;
              color: $color-black;
            }
          }
        }
      }

      .button-secondary {
        margin-top: 20px;
        background-color: $button-primary-background;
        color: $button-text-color;
        border-radius: $button-border-radius;
        padding: 10px 20px;
        width: 100%;
        border: none;
        font-size: 18px;
        cursor: pointer;
        transition: background-color 0.3s;
        font-weight: $font-weight-light;

        &:hover {
          background-color: $button-primary-hover-background;
        }

        &:active {
          background-color: $button-primary-background;
          color: $button-text-color;
        }
      }
    }

    .navbar-links {
      list-style: none;
      display: flex;
      gap: 20px;
      margin-left: auto;
      padding: 0;
      padding-right: 20px;

      li {
        a {
          font-family: $font-family-primary;
          font-size: $font-size-small;
          font-weight: $font-weight-light;
          color: $color-black;
          text-decoration: none;
          transition: color 0.3s;

          &:hover {
            color: darken($color-black, 20%);
          }

          &.active {
            font-weight: $font-weight-semi-bold;
            color: $color-black;
          }
        }
      }
    }

    .button-primary {
      background-color: $button-primary-background;
      color: $button-text-color; 
      border-radius: $button-border-radius;
      padding: 10px 20px;
      border: none;
      font-size: 18px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: $button-primary-hover-background;
        /* Ne mijenja se boja teksta na hover */
      }

      &:active {
        background-color: $button-primary-background;
        color: $button-text-color; 
      }
    }

    .burger-icon {
      display: none;
      cursor: pointer;

      img {
        height: 30px;
        width: 30px;
      }
    }

    @include mobile {
      .burger-icon {
        display: block;
      }

      .navbar-links {
        display: none;
      }

      .button-primary {
        display: none;
      }

      .navbar {
        margin-top: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    margin-top: 0;
  }
}

@import './_variables';
@import './_mixins';

body {
    margin: 0;
    font-family: $font-family-primary !important;
}

.heading {
    font-family: $font-family-primary;
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    line-height: $line-height-large;
    text-align: $text-align-center;
}

.subheading {
    font-family: $font-family-primary;
    font-size: $font-size-medium;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-medium;
    text-align: $text-align-center;
}

.text {
    font-family: $font-family-primary;
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    line-height: $line-height-small;
    text-align: $text-align-center;
}

.button-primary {
    background-color: $button-primary-background;
    color: $button-text-color;
    border-radius: $button-border-radius;
    padding: 10px 20px;
    border: none;
    font-size: $font-size-small;
    cursor: pointer;
    
    &:hover {
      background-color: $button-primary-hover-background;
      color: $button-hover-text-color;
    }
}

.button-secondary {
    background-color: $button-secondary-background;
    color: $button-text-color;
    border-radius: $button-border-radius;
    padding: 10px 20px;
    border: 1px solid $color-black;
    font-size: $font-size-small;
    cursor: pointer;
    
    &:hover {
      background-color: $button-secondary-hover-background;
      color: $button-hover-text-color;
    }
}

@import '../../scss/_variables';

.menu-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 50px;

  .coffee-menu-image {
    box-sizing: border-box;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

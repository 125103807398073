@import '../../scss/_variables';

.contact {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  background-color: $background-light;
  font-family: $font-family-primary;
  margin-top: 150px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  @media (max-width: 1000px) {
    margin-bottom: 0;
  }
  &__map-section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    width: 48%;
    max-width: 96%;

    .title {
      font-family: $font-family-primary;
      font-size: 48px;
      font-weight: 700;
      line-height: 72px;
      text-align: left;
      color: $background-dark;
      padding-top: 20px;

      @media (max-width: 1000px) {
        padding-top: 0;
        font-size: 32px;
      }
    }

    .contact__map-image {
      width: 100%;
      border-radius: 8px;
      max-width: 550px;

      // Postavljanje širine ispod 1000px
      @media (max-width: 1000px) {
        max-width: 450px; // Isto kao ostale slike
        width: 94%;       // Usklađeno sa dugmadi
      }
    }

    .contact__info {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      &-item {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: $font-size-small;
        color: $color-black;
        font-family: $font-family-primary;
        margin-bottom: 10px;

        .contact__icon {
          width: 33px;
        }
      }
    }

    .contact__buttons {
      display: flex;
      gap: 1rem;

      @media (max-width: 1000px) {
        flex-direction: column;
        width: 94%;

        .button-primary1,
        .button-secondary1 {
          width: 94%;
          max-width: none;
          margin-bottom: 1rem;
        }
      }
    }
  }

  &__images-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    transform: translateY(-100px);
    width: 48%;
    max-width: 96%;
    align-items: center;

    .contact__image {
      width: 100%;
      border-radius: 8px;
      max-width: 450px;
      align-items: center;
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding-top: 0;

    &__images-section {
      order: -1;
    }

    &__map-section,
    &__images-section {
      width: 96%;
      align-items: center;
      transform: translateY(-180px);
    }
  }
}

// Stil za dugmad
.button-primary1 {
  font-family: $font-family-primary;
  font-weight: $font-weight-light;
  padding: 15px 30px;
  border-radius: $button-border-radius;
  background-color: $button-primary-background;
  color: $color-white;
  border: none;
  cursor: pointer;
  font-size: $font-size-small;
  transition: background-color 0.3s;
  text-align: center;
  max-width: 240px;

  &:hover,
  &:active {
    background-color: $button-primary-hover-background;
    color: $color-white !important; // Ensures text color stays white
  }
}

.button-secondary1 {
  font-family: $font-family-primary;
  font-weight: $font-weight-light;
  padding: 15px 30px;
  border-radius: $button-border-radius;
  background-color: transparent;
  color: $button-primary-background;
  border: 2px solid $button-primary-background;
  cursor: pointer;
  font-size: $font-size-small;
  transition: background-color 0.3s;
  text-align: center;
  max-width: 240px;

  &:hover,
  &:active {
    background-color: $button-secondary-hover-background;
    color: $button-primary-background !important; // Ensures text color stays consistent
  }
}

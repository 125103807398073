@import '../../scss/_variables';

.footer {
  background-color: $background-dark;
  padding: 1.5rem;
  color: $color-white;

  .footer__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  .footer__logo-section {
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
      flex-direction: column;
      margin-bottom: 1rem;
    }

    .footer__logo {
      width: 202px;
      height: auto;
      margin-right: 0.5rem;

      @media (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 0.5rem;
      }
    }

    .footer__text {
      .footer__title {
        font-family: $font-family-primary;
        font-size: $font-size-medium;
        font-weight: $font-weight-semi-bold;
        line-height: $line-height-small;
        color: $color-white;
      }

      .footer__subtitle {
        font-family: $font-family-primary;
        font-size: $font-size-small;
        font-weight: $font-weight-light;
        line-height: $line-height-small;
        color: $color-white;
      }
    }
  }

  .footer__rights {
    font-family: $font-family-primary;
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    line-height: $line-height-small;

    @media (max-width: 600px) {
      margin-top: 1rem;
    }
  }
}

.quotes-section {
    width: 100%;
    height: auto;
    background-size: cover;
    background-position: center;
    padding: 40px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;
    margin-top: 50px;
    box-sizing: border-box;
  
    // Desktop background
    background-image: url('../../assets/images/desktop.png');
  
    .desktop-quote, .mobile-quote {
      font-family: 'Nunito', sans-serif;
      font-size: 26px;
      font-style: italic;
      font-weight: 300;
      line-height: 35.46px;
      text-align: left;
      display: none;
      width: 90%;
    }
  
    .desktop-quote {
      display: block;
    }
  
    @media screen and (max-width: 768px) {
      // Mobile background
      background-image: url('../../assets/images/mobile.png');
  
      .desktop-quote {
        display: none;
      }
  
      .mobile-quote {
        display: block;
        p {
          margin: 0;
          font-size: 35px;
          font-weight: 300;
        }
        blockquote {
          margin: 0;
        }
      }
    }
  }
  
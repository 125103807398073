@import '../../scss/_variables';

.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  padding-top: 0;
  @media (max-width: 900px) {
    position: relative;
    top: -100px;
  }


  .title {
    font-family: $font-family-primary;
    font-size: $font-size-medium;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-medium;
    text-align: $text-align-center;
    color: $background-dark;
    margin-bottom: 1rem;
    margin-top: 0;
    @media (max-width: 900px) {
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        text-align: center;
      }
  }


  .subtitle {
    font-family: $font-family-primary;
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    line-height: $line-height-small;
    text-align: $text-align-center;
    color: $color-black;
    margin-bottom: 2rem;
    @media (max-width: 900px) {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        text-align: center;
      }
  }

  .testimonials__grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    width: 95%;
    height: 400px;
    @media (max-width: 1000px) {
      height: auto;
    }

    .testimonial {
      width: 24%;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .testimonial__header {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:nth-child(odd) {
        align-self: start;
      }
      
      &:nth-child(even) {
        align-self: end;
      }

      .testimonial__image {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        object-fit: cover;
        margin-right: 5px;
      }

      .testimonial__name {
        font-family: $font-family-primary;
        font-size: 24px;
        font-weight: $font-weight-semi-bold;
        line-height: 36px;
        text-align: left;
        color: $color-black;
        margin: 0.5rem 0;
      }

      .testimonial__text {
        font-family: $font-family-primary;
        font-size: $font-size-small;
        font-weight: $font-weight-light;
        line-height: $line-height-small;
        text-align: left;
        color: $color-black;
        @media (max-width: 900px) {
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            text-align: left;
            
          }
      }
    }
  }

  // Media query for screens 1100px and below
  @media (max-width: 1100px) {
    .testimonials__grid {
      flex-direction: column;
      align-items: flex-start;

      .testimonial {
        width: 100%;
        align-self: flex-start;
        margin-bottom: 2rem; // Adds spacing between testimonials
      }
    }
  }
}
@import '../../scss/_variables';

.hero {
  text-align: $text-align-center;
  padding: 100px 20px;
  background-color: $background-light;
  position: relative;
  padding-bottom: 20px;

  .hero-content {
    width: 90%;
    max-width: 890px;
    margin: 0 auto;

    .hero-text-desktop {
      display: block;

      .hero-title {
        font-family: $font-family-primary;
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        line-height: $line-height-large;
        color: $color-black;

        .highlight {
          color: $background-dark;
        }
      }

      .hero-description {
        font-family: $font-family-primary;
        font-size: $font-size-small;
        font-weight: $font-weight-light;
        line-height: $line-height-small;
        color: $color-black;
        margin-top: 20px;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .hero-text-mobile {
      display: none;

      .hero-title-smaller {
        font-family: $font-family-primary;
        font-size: $font-size-medium;
        font-weight: $font-weight-bold;
        line-height: $line-height-medium;
        color: $color-black;

        .highlight {
          color: $background-dark;
        }
      }

      .hero-description-smaller {
        font-family: $font-family-primary;
        font-size: $font-size-small;
        font-weight: $font-weight-light;
        line-height: $line-height-small;
        color: $color-black;
        margin-top: 20px;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .hero-buttons {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 40px;
      align-items: center;

      .button-primary, .button-secondary {
        font-family: $font-family-primary;
        font-weight: $font-weight-light;
        padding: 15px 30px;
        border-radius: $button-border-radius;
        border: none;
        cursor: pointer;
        font-size: $font-size-small;
        transition: background-color 0.3s;
        text-align: center;
        max-width: 240px;

        &:hover {
          background-color: $button-primary-hover-background;
        }
      }

      .button-primary {
        background-color: $button-primary-background;
        color: $button-text-color;

        &:hover {
          background-color: $button-primary-hover-background;
        }
      }

      .button-secondary {
        background-color: $button-secondary-background;
        color: $background-dark;
        border: 2px solid $background-dark;

        &:hover {
          background-color: $button-secondary-hover-background;
        }
      }
    }
  }

  /* Stilovi za ikone */
  .icon-section {
    width: 300px;
    margin: 0 auto;  /* Centriranje unutar roditelja */
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;

    .icon-holder {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      img {
        width: 93px;
        height: 86px;
        margin-bottom: 10px;
      }
    }

    /* Sakrij "Organic" i "Sweets" na desktop verziji */
    .icon-holder:nth-child(4),
    .icon-holder:nth-child(5) {
      display: none;
    }
  }

  .powered-by {
    margin-top: 40px;

    img {
      width: auto;
      height: 106px;
    }
  }

  .hero-image-left {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 500px;
  }

  .hero-image-right {
    position: absolute;
    bottom: -130px;
    right: 0;
    width: 500px;
  }

  /* Media query za širinu do 1200px */
  @media screen and (max-width: 1200px) {
    .hero-image-left {
      width: 330px;
      transform: translateY(-60%);
    }

    .hero-image-right {
      width: 400px;
      bottom: -150px;
    }
  }

  /* Media query za širinu do 930px */
  @media screen and (max-width: 930px) {
    padding-top: 20px;

    .hero-title {
      font-size: $font-size-medium;
      line-height: $line-height-medium;
    }

    .hero-description {
      font-size: $font-size-small;
      line-height: $line-height-small;
      max-width: 500px;
    }

    .hero-buttons {
      flex-direction: column;
      gap: 10px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      .button-primary, .button-secondary {
        width: 100%;
        padding: 10px 0;
        max-width: 180px !important;
      }
    }

    .hero-image-left {
      width: 220px;
      top: 57%;
    }

    .hero-image-right {
      width: 260px;
      bottom: -80px;
    }

    .hero-text-desktop {
      display: none !important;
    }

    .hero-text-mobile {
      display: block !important;
    }

    /* Prikaži "Organic" i "Sweets" na mobilnim uređajima */
    .icon-holder:nth-child(4),
    .icon-holder:nth-child(5) {
      display: flex !important;
    }

    .icon-holder:nth-child(3) {
      display: none !important;
    }

    /* Raspored ikonica u dva reda koristeći CSS Grid */
    .icon-section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 10px; 
      row-gap: 20px;

      .icon-holder {
        justify-content: center;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .hero-image-left {
      width: 160px !important;
    }
    .hero-image-right {
      width: 190px;
      bottom: -60px;
    }    
  }
}

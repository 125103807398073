// src/scss/_variables.scss

// Fonts
$font-family-primary: 'Nunito', sans-serif;

// Font Sizes
$font-size-large: 64px;   
$font-size-medium: 48px;  
$font-size-small: 16px;  

// Font Weights
$font-weight-bold: 800;
$font-weight-semi-bold: 700;
$font-weight-light: 300;

// Line Heights
$line-height-large: 96px;  
$line-height-medium: 72px;
$line-height-small: 24px; 

// Text Align
$text-align-center: center;

// Colors
$color-black: #000000;
$color-white: #FFFFFF;
$background-light: #FFF7EB;
$background-dark: #C38154;

// Button Colors
$button-primary-background: $background-dark;  
$button-secondary-background: $background-light; 
$button-text-color: $color-white;

// Button Hover Colors
$button-primary-hover-background: darken($background-dark, 10%);
$button-secondary-hover-background: lighten($background-light, 10%);
$button-hover-text-color: $color-black;

// Border Radius
$button-border-radius: 32px;

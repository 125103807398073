
  @mixin for-large-screens {
    @media screen and (max-width: 1200px) {
      @content;
    }
  }
  
  @mixin for-medium-screens {
    @media screen and (max-width: 930px) {
      @content;
    }
  }
  
  @mixin mobile {
    @media screen and (max-width: 768px) {
      @content;
    }
  }
  
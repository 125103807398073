@import '../../scss/_variables';

.product-section {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    padding: 20px;
    .product-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
            font-family: $font-family-primary;
            font-size: 32px;
            font-weight: $font-weight-semi-bold;
            line-height: 48px;
            text-align: center;
            color: $background-dark;            
        }
        img {
            margin-right: 5px;
            width: 25px;
        }
    }
    .product-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        .product-item {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 45%;
            h3 {
                text-align: center;
                font-family: $font-family-primary;
                font-size: 22px;
                font-weight: $font-weight-semi-bold;
                line-height: 33px;
                text-align: center;

            }
            p {
                text-align: center;
                font-family: $font-family-primary;
                font-size: 14px;
                font-weight: $font-weight-light;
                line-height: 21px;
                text-align: center;
                max-width: 100%;
                
            }
        }
    }
}

/* Hide product section on larger screens */
@media screen and (min-width: 768px) {
  .product-section {
    display: none;
  }
}